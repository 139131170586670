<template>
    <div class="wrapper not-found">
        <div class="content">
            
            
            <div class="no-data-message">
                <div class="icon"><i class="fa-solid fa-magnifying-glass"></i></div>
                <div>
                    <div class="four-zero-four">404</div>
                    <div class="lost-text">UH OH! You're lost.</div>
                    <div class="message">The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</div>
                </div>
                <router-link to="/"><Button text="Go to home page" theme="white"/></router-link>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.no-data-message {
    display: grid;
    padding: 100px 30px;
    align-items: center;
    justify-items: center;
    row-gap: 20px;
    .icon {
        font-size: 50px;
        color: $bgShade;
    }
    .message {
        color: $textShade;
    }
}

.not-found {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.four-zero-four {
    font-weight: 300;
    font-size: 50px;
}

.lost-text {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 10px;
}

.content {
    max-width: 450px;
    text-align: center;
}

</style>